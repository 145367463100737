import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakInitOptions } from 'keycloak-js';
import { get } from 'lodash';
import { debounceTime, fromEvent, merge, of, Subscription } from 'rxjs';
import { LOGIN_REDIRECT_PATH } from 'src/app/shared/components/app-settings-dialog/app-settings-dialog.component';
import { GlobalLaborJobService } from 'src/app/shared/services/global-labor-job.service';
import { environment } from 'src/environments/environment';
import { NotifyService } from './notify.service';
import { UserReportService } from './user-report.service';
import { AccessTokenService } from './access-token.service';
import { CloudApiService } from './cloud-api.service';

const IDLE_TIME_SECONDS_KEY = 'idle_time_seconds';
const IDLE_SINCE_TIMESTAMP_KEY = 'idle_since_timestamp';

const MS_TILL_REFRESH = 1000 * 60 * 60; // (60 minutes) Refresh if access token is within this many ms from expiration
const MS_TILL_WARNING = 1000 * 60 * 15; // (15 minutes) Warn user this many ms before their session is forcably expired

let warnedNearExpiration = false;

@Injectable()
export class AuthService {
  private loginCheckInterval: ReturnType<typeof setInterval> | null = null;
  private keycloakInitialized = false;

  autoTimeoutSubscription?: Subscription;

  constructor(
    private http: HttpClient,
    private userReportApi: UserReportService,
    private keycloak: KeycloakService,
    private notifyService: NotifyService,
    private globalLaborJobService: GlobalLaborJobService,
    private accessTokenService: AccessTokenService,
    private cloudApiService: CloudApiService
  ) {
    this.initAutoLogout();
  }

  private initAutoLogout() {
    this.autoTimeoutSubscription?.unsubscribe();
    const interruptions$ = merge(
      // start time out without requiring any mouse move or keydown
      of(true),
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'keydown')
    );

    this.autoTimeoutSubscription = interruptions$
      // if idle time seconds is null then then wait forever before logging out
      .pipe(debounceTime((this.getIdleTimeSeconds() || 1_000_000) * 1000))
      .subscribe(() => {
        this.logout();
      });

    // Logout on app init if
    this.maybeLogoutOnInit();

    // Piggy back onto the existing idle auto-logout for now.  We may want to
    // consider a separate heartbeat subscription mechanism which is tied to the
    // app simply being open so that we can independently tune the thresholds,
    // but this should be a sufficient start.
    interruptions$.subscribe(() => {
      this.setLastInterruptionTime(+new Date());
    });
  }

  maybeLogoutOnInit() {
    const maxIdleTime = this.getIdleTimeSeconds();
    const lastInterruptionAt = this.getLastInterruptionTime();
    if (!lastInterruptionAt || !maxIdleTime) {
      return;
    }
    const idleTime = (+new Date() - lastInterruptionAt) / 1000;
    if (idleTime > maxIdleTime) {
      this.logout();
    }
  }

  setLastInterruptionTime(timestamp: number) {
    localStorage.setItem(IDLE_SINCE_TIMESTAMP_KEY, timestamp.toString());
  }

  getLastInterruptionTime() {
    const savedValue = localStorage.getItem(IDLE_SINCE_TIMESTAMP_KEY);
    if (savedValue) {
      return +savedValue;
    }
    return null;
  }

  getIdleTimeSeconds(): number | null {
    const savedValue = localStorage.getItem(IDLE_TIME_SECONDS_KEY);
    if (savedValue) {
      return +savedValue;
    }
    return null;
  }

  setIdleTimeSeconds(idleTimeSeconds: number | null) {
    if (idleTimeSeconds === null) {
      localStorage.removeItem(IDLE_TIME_SECONDS_KEY);
    } else {
      localStorage.setItem(IDLE_TIME_SECONDS_KEY, idleTimeSeconds.toString());
    }
    this.initAutoLogout();
  }

  upsertAccessToken(newToken: string): void {
    const existing = this.accessTokenService.getRawAccessToken();
    const isNewLogin = !existing || !this.accessTokenService.isTokenFresh(0);
    this.accessTokenService.setAccessToken(newToken);

    if (isNewLogin) {
      this.userReportApi.report('userLogin', {}).subscribe();
    }
  }

  async isSessionNearExpiration() {
    if (await this.accessTokenService.isLoginFresh(MS_TILL_WARNING)) {
      return false;
    }
    const sessionEndTime = await this.getSessionEndTime();
    if (!sessionEndTime) {
      return null;
    }
    const now = new Date().getTime();
    return sessionEndTime.getTime() - now < MS_TILL_WARNING;
  }

  async getSessionEndTime(): Promise<Date | null> {
    this.ensureKeycloakInitialized(false);
    if (!(await this.accessTokenService.isLoginFresh(0))) {
      return new Date(0);
    }
    return this.accessTokenService.getTokenExpirationDate(
      this.keycloak.getKeycloakInstance().refreshToken
    );
  }

  async ensureKeycloakInitialized(forceLogin: boolean): Promise<boolean> {
    if (this.keycloakInitialized) {
      return (
        (await this.keycloak.isLoggedIn()) &&
        !(await this.keycloak.isTokenExpired())
      );
    }
    this.keycloakInitialized = true;
    const redirectUri = localStorage.getItem(LOGIN_REDIRECT_PATH);
    const initOptions: KeycloakInitOptions = {
      onLoad: forceLogin ? 'login-required' : undefined,
      redirectUri: redirectUri
        ? window.location.origin + redirectUri
        : window.location.href,
    };
    const existingToken = this.accessTokenService.getRawAccessToken();
    if (existingToken) {
      initOptions.token = existingToken;
    }
    const result = await this.keycloak.init({
      enableBearerInterceptor: false,
      config: {
        url: environment.KC_BASE_URL,
        realm: 'fulfil',
        clientId: 'dashboard',
      },
      initOptions,
    });

    const kcToken = await this.keycloak.getToken();
    this.upsertAccessToken(kcToken);

    return result;
  }

  async refreshLogin() {
    this.ensureKeycloakInitialized(false);
    this.keycloak
      .updateToken(-1)
      .then(async () => {
        this.upsertAccessToken(await this.keycloak.getToken());
      })
      .catch((e) => {
        console.log(`Auth: KC Token Refresh failed: ${e.message}`);
      });
  }

  /**
   * Method that is called when the app is bootstrapped
   */
  //
  async authenticate(): Promise<void> {
    // Don't fuss with Keycloak as a hard dependency if login is sufficiently fresh
    if (await this.accessTokenService.isLoginFresh(MS_TILL_WARNING)) {
      this.initLoginCheckInterval();
      return;
    }

    try {
      const authenticated = await this.ensureKeycloakInitialized(true);
      if (!authenticated) {
        console.error('Auth: KC not authenticated...');
        // To Do: UI / UX to notify user
        return;
      }

      warnedNearExpiration = false;
      console.log(
        `Auth: Logged in from ${this.accessTokenService
          .getTokenExpirationDate()
          ?.toISOString()} to ${(
          await this.getSessionEndTime()
        )?.toISOString()}`
      );
      this.initLoginCheckInterval();
    } catch (error: unknown) {
      const err = error as Error;
      this.notifyService.showToastWithConfirm(
        `Error authenticating: ${err.message}`
      );
      console.error(error);
    }
  }

  initLoginCheckInterval() {
    if (this.loginCheckInterval) {
      clearInterval(this.loginCheckInterval);
    }
    this.loginCheckInterval = setInterval(async () => {
      if (!(await this.accessTokenService.isLoginFresh(0))) {
        this.logout();
      }
      // If session is nearly expired, warn about auto-log-out
      else if (
        !warnedNearExpiration &&
        (await this.isSessionNearExpiration())
      ) {
        this.notifyService.showToastWithConfirm(
          `Your login will expire in ${Math.round(
            MS_TILL_WARNING / 1000 / 60
          )} minutes, please Logout to avoid interruption.`
        );
        warnedNearExpiration = true;
      } else if (
        !warnedNearExpiration &&
        !(await this.accessTokenService.isLoginFresh(MS_TILL_REFRESH))
      ) {
        this.refreshLogin();
      }
    }, 30_000);
  }

  async logout() {
    this.ensureKeycloakInitialized(false);
    if (this.loginCheckInterval) {
      clearInterval(this.loginCheckInterval);
    }
    try {
      await this.globalLaborJobService.completeAllActiveJobs();
    } catch (e) {
      // swallow error, the user should still get logged out
    }
    this.loginCheckInterval = null;
    // Remember token for remainder of this fn call
    const token = this.accessTokenService.getDecodedJwt();

    // Because of a bug in KC's Cognito SSO handling, we have to rely
    // on Fulfil-api to do the logout since the User cannot directly with KC.
    await new Promise((resolve) => {
      this.http
        .post(this.cloudApiService.buildUrl('admin/logout'), {})
        .subscribe(
          () => {
            resolve({});
          },
          () => {
            // Resolve on error and hope the vanilla KC logout works...
            this.accessTokenService.deleteAccessToken();
            this.keycloak.logout();
            resolve({});
          }
        );
    });
    // Immediately delete token to kinda log out locally
    this.accessTokenService.deleteAccessToken();

    const loginUrl = `${window.location.origin}/`;
    // If a 3rd party logout is also needed, forward to their logout URL but
    // redirect back to this page.
    if (token?.data.email.endsWith('@amazon.com')) {
      console.info('Logout Cognito');
      const cognitoLogoutUrl = `${
        environment.AMAZON_LOGOUT
      }&logout_uri=${encodeURIComponent(`${loginUrl}`)}`;
      window.location.href = cognitoLogoutUrl;
    } else {
      window.location.href = loginUrl;
    }
  }

  getUserId(): string {
    const token: { data: { id: string } } | null =
      this.accessTokenService.getDecodedJwt();
    return get(token, 'data.id', '');
  }
}
