import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AccessTokenService } from './access-token.service';

@Injectable()
export class AuthGuard {
  constructor(
    private router: Router,
    private accessTokenService: AccessTokenService
  ) {}

  /**
   * if a route has claims ensure user has those claims
   * otherwise user is shown unauthorized page
   */
  canActivate(route: ActivatedRouteSnapshot) {
    const { claims } = route.data;
    if (!claims) {
      return true;
    }
    const isAuthorized = claims.every(
      ({
        claim,
        claimValue,
      }: {
        claim: string;
        claimValue: string | boolean;
      }) => this.accessTokenService.hasClaim(claim, claimValue)
    );
    if (!isAuthorized) {
      this.router.navigate(['/unauthorized']);
      return false;
    }
    return true;
  }
}
