import { PlatformModule } from '@angular/cdk/platform';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { JWT_OPTIONS, JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import * as Sentry from '@sentry/angular';
import { KeycloakAngularModule } from 'keycloak-angular';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { lastValueFrom } from 'rxjs';
import version from '../assets/version.json';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { ChunkLoadErrorHandler } from './core/interceptors/chunk-load-error-handler';
import { AuthService } from './core/services/auth.service';
import { CloudApiService } from './core/services/cloud-api.service';
import { DynamicEnvVarsService } from './core/services/dynamic-env-vars.service';
import { FrapiService } from './core/services/frapi.service';
import { StartupCheckService } from './core/services/startup-check/startup-check.service';
import { UserReportService } from './core/services/user-report.service';

export function loadStartup(
  startupCheckService: StartupCheckService,
  authService: AuthService,
  dynamicEnvVarsService: DynamicEnvVarsService
) {
  return async (): Promise<any> => {
    await lastValueFrom(dynamicEnvVarsService.load$);
    await authService.authenticate();
    startupCheckService.emitStarted();
  };
}

Sentry.init({
  dsn: environment.sentry.dsn || undefined,
  environment: environment.sentry.environment,
  // TODO: https://docs.sentry.io/platforms/javascript/guides/angular/configuration/releases/#bind-the-version
  enabled: environment.sentry.enabled,
  release: version.version,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /fulfil-api-prod\.fulfil-api\.com/,
    /fulfil-api-staging\.np-fulfil-api\.com/,
    /^\//,
  ],
  tracesSampleRate: 0.5,
});
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    PlatformModule,
    KeycloakAngularModule,
    HttpClientModule,
    JwtModule,
  ],
  providers: [
    ChunkLoadErrorHandler,
    DynamicEnvVarsService,
    AuthService,
    UserReportService,
    FrapiService,
    CloudApiService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadStartup,
      deps: [StartupCheckService, AuthService, DynamicEnvVarsService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor,@typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  constructor(trace: Sentry.TraceService) {}
}
