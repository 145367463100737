import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get, has, merge } from 'lodash';
import { Observable, map, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { errorHandler, handleApiError } from './api-error.handler';
import { FrapiResponse } from '../types';
import { StartupCheckService } from './startup-check/startup-check.service';
import { AccessTokenService } from './access-token.service';

@Injectable({
  providedIn: 'root',
})
export class FrapiService {
  constructor(
    private http: HttpClient,
    private startupCheckService: StartupCheckService,
    private accessTokenService: AccessTokenService
  ) {}

  public buildUrl(facility: string, endpoint: string) {
    // TODO: implement URL template.
    const base = environment.FRAPI_URL_TEMPLATE.replace(
      // eslint-disable-next-line no-template-curly-in-string
      '${facilityIdentifier}',
      facility
    );
    return `${base}${endpoint}`;
  }

  private authHeaders(): { Authorization: string } | {} {
    const token = this.accessTokenService.getRawAccessToken();
    if (!token) {
      console.warn('FRAPI: Access token not found.');
      return {};
    }

    return { Authorization: `Bearer ${token}` };
  }

  handleFrapiErrorResponse<T>(apiCall: Observable<T>) {
    return apiCall.pipe(
      errorHandler(
        (error) => has(error, 'error.responseException.exceptionMessage'),
        (error) => get(error, 'error.responseException.exceptionMessage')
      )
    ) as Observable<T>;
  }

  queryAbstract<T>(
    facility: string,
    endpoint: string,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    headers = {},
    verb: 'get' | 'delete'
  ): Observable<T> {
    const headersWithAuth = merge(headers, this.authHeaders());
    return this.startupCheckService.startupCheck$.pipe(
      switchMap(() =>
        this.http[verb](this.buildUrl(facility, endpoint), {
          headers: headersWithAuth,
        })
      ),
      handleApiError,
      this.handleFrapiErrorResponse
    ) as Observable<T>;
  }

  saveAbstract<T>(
    facility: string,
    endpoint: string,
    body: any,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    headers = {},
    verb: 'post' | 'put' | 'patch'
  ): Observable<T> {
    const headersWithAuth = merge(headers, this.authHeaders());
    return this.startupCheckService.startupCheck$.pipe(
      switchMap(() =>
        this.http[verb]<T>(this.buildUrl(facility, endpoint), body, {
          headers: headersWithAuth,
        })
      ),
      handleApiError,
      this.handleFrapiErrorResponse
    );
  }

  public get<T>(
    facility: string,
    endpoint: string,
    headers = {}
  ): Observable<T> {
    return this.queryAbstract<T>(facility, endpoint, headers, 'get');
  }

  public getJson<T>(
    facility: string,
    endpoint: string,
    headers = {
      responseType: 'json',
    }
  ): Observable<T> {
    return this.queryAbstract<FrapiResponse<T>>(
      facility,
      endpoint,
      headers,
      'get'
    ).pipe(map(({ result }) => result));
  }

  public delete<T>(
    facility: string,
    endpoint: string,
    headers = {},
    body = {}
  ): Observable<T> {
    return this.http
      .delete<T>(this.buildUrl(facility, endpoint), {
        body,
        headers: merge(headers, this.authHeaders()),
      })
      .pipe(handleApiError, this.handleFrapiErrorResponse);
  }

  public post<T>(
    facility: string,
    endpoint: string,
    body: any,
    headers = {}
  ): Observable<T> {
    return this.saveAbstract<T>(facility, endpoint, body, headers, 'post');
  }

  public patch<T>(
    facility: string,
    endpoint: string,
    body: any,
    headers = {}
  ): Observable<T> {
    return this.saveAbstract<T>(facility, endpoint, body, headers, 'patch');
  }

  public put<T>(
    facility: string,
    endpoint: string,
    body: any,
    headers = {}
  ): Observable<T> {
    return this.saveAbstract<T>(facility, endpoint, body, headers, 'put');
  }
}
