import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

type JwtData = {
  [claim: string]: boolean | string | string[];
} & {
  email: string;
  id: string;
};

type JwtSchema = { sub: string; data: JwtData };

const ACCESS_TOKEN_KEY = 'access_token';

@Injectable({
  providedIn: 'root',
})
export class AccessTokenService {
  constructor(private jwtHelperService: JwtHelperService) {}

  private cachedDecode: JwtSchema | null = null;

  getRawAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  setAccessToken(accessToken: string | undefined) {
    this.cachedDecode = null;
    if (accessToken) {
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    }
  }

  deleteAccessToken() {
    this.cachedDecode = null;
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  /**
   * Returns true if the current JWT localstorage token is valid and not near expiration
   * @param staleThresholdMs Token must live for at least this much longer to be considered valid, default 1 hour
   * @returns
   */
  async isLoginFresh(staleThresholdMs: number): Promise<boolean | null> {
    return this.isTokenFresh(staleThresholdMs);
  }

  hasClaim(claim: string, claimValue: boolean | string): boolean {
    const claims = this.getDecodedJwt()?.data;

    if (!claims) {
      return false;
    }

    return claims[claim] === claimValue;
  }

  userWasAssignedARole(): boolean {
    const jwtData = this.getDecodedJwt();
    if (!jwtData) {
      return false;
    }
    // JWT object should have more than just JwtBaseProps
    return Object.keys(jwtData).length > 5;
  }

  getTokenExpirationDate(token?: string | null | undefined): Date {
    if (token) {
      const decoded = this.jwtHelperService.decodeToken(token);
      return new Date(decoded.exp * 1000);
    }
    const decoded = this.getDecodedJwt() as any;
    if (!decoded) {
      return new Date(0);
    }
    return new Date(decoded.exp * 1000);
  }

  isTokenFresh(staleThresholdMs: number): boolean | null {
    const decoded = this.getTokenExpirationDate();
    if (!decoded) {
      return null;
    }
    const expirationDate = decoded.getTime();
    const now = Date.now();
    const msTillExpired = expirationDate - now;
    return msTillExpired > staleThresholdMs;
  }

  getDecodedJwt(): JwtSchema | null {
    const accessToken = this.getRawAccessToken();
    if (!this.cachedDecode) {
      if (!accessToken) {
        return null;
      }
      this.cachedDecode = this.jwtHelperService.decodeToken(accessToken);
    }
    return this.cachedDecode;
  }
}
