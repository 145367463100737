import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Facility } from '@fulfil0518/fulfil-api-libs/facility';
import { VendorStore } from '@fulfil0518/fulfil-api-libs/vendor-store';
import { isEqual } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  lastValueFrom,
  Observable,
  of,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  tap,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CloudApiService } from './cloud-api.service';

export const FACILITY_IDENTIFIER_KEY = 'MULTISTORE:FACILITY-IDENTIFIER';
export const FACILITY_URL_KEY = 'facility';

export const FACILITY_VENDOR_STORES_KEY = 'facility_vendorstores';
export const VENDOR_STORE_URL_KEY = 'vendorstore';

export const PIONEER = 'pioneer';
export const PLM = 'plm';
export const AMAZON_PLM = 'amazon-plm';
const SHASTA = 'shasta';
const FULFIL = 'fulfil';

const FULFIL_SHASTA = `${FULFIL}-${SHASTA}`;
export const LUCKY = 'lucky';
export const LUCKY_PIONEER = `${LUCKY}-${PIONEER}`;
export const FULFIL_PIONEER = `${FULFIL}-${PIONEER}`;

type MultiStoreContext = {
  facility: string;
  vendorStore: string;
};

@Injectable({
  providedIn: 'root',
})
export class MultiStoreService {
  public readonly facilityContext$: Observable<string>;
  public readonly multiStoreContext$: Observable<MultiStoreContext>;
  public readonly vendorStoreContext$: Observable<string>;
  public readonly facilities$ = this.cloudApi
    .get<Facility[]>('facilities')
    .pipe(shareReplay(1));
  private multiStoreContextSubject: BehaviorSubject<MultiStoreContext>;
  public readonly vendorStores$ = this.cloudApi
    .get<VendorStore[]>('vendorstores')
    .pipe(shareReplay(1));

  constructor(
    private cloudApi: CloudApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    const facility = this.initFacility();
    const vendorStore = this.confirmVendorStore(this.initVendorStore(facility));
    this.multiStoreContextSubject = new BehaviorSubject({
      facility,
      vendorStore,
    });
    this.multiStoreContext$ = this.multiStoreContextSubject.asObservable().pipe(
      distinctUntilChanged(isEqual),
      tap(() => {
        this.saveFacilityAndVendorStoreInLocalStorage();
      }),
      shareReplay()
    );
    // this should emit only when the facility has changed
    this.facilityContext$ = this.multiStoreContext$.pipe(
      map((multiStoreContext) => multiStoreContext.facility),
      distinctUntilChanged(),
      tap((facilityIdentifier) => {
        localStorage.setItem(FACILITY_IDENTIFIER_KEY, facilityIdentifier);
      }),
      shareReplay()
    );
    // this should emit only when the vendor store has changed
    this.vendorStoreContext$ = this.multiStoreContext$.pipe(
      map((multiStoreContext) => multiStoreContext.vendorStore),
      distinctUntilChanged(),
      shareReplay()
    );
    const queryParams$ = this.activatedRoute.queryParams.pipe(
      filter((params: Params) => params.facility || params.vendorstore),
      tap((params: Params) => {
        if (params.vendorstore) {
          const vendorstore = this.confirmVendorStore(params.vendorstore);
          if (vendorstore !== params.vendorstore) {
            const urlTree = this.router.createUrlTree([], {
              queryParams: { vendorstore },
              queryParamsHandling: 'merge',
              preserveFragment: true,
            });

            this.router.navigateByUrl(urlTree);
          }
        }
      })
    );
    // we don't need to clean up this subscription
    // because we need it throughout the entire lifecycle of the app
    combineLatest([
      queryParams$,
      this.facilities$,
      this.vendorStores$,
    ]).subscribe(([params, facilities, vendorStores]) => {
      if (
        params.facility &&
        params.facility !== this.getCurrentFacilityIdentifier()
      ) {
        const facilityId = facilities.find(
          (facilityInfo) => facilityInfo.identifier === params.facility
        )?.id;
        const firstVendorStoreInFacility = vendorStores.find(
          (vendorStoreInfo) => vendorStoreInfo.facility_id === facilityId
        )?.identifier;
        this.multiStoreContextSubject.next({
          facility: params.facility,
          vendorStore:
            this.latestFacilityVendorStore(params.facility) ||
            firstVendorStoreInFacility!,
        });
      } else if (
        params.vendorstore &&
        params.vendorstore !== this.getCurrentVendorStoreIdentifier()
      ) {
        const facilityId = vendorStores.find(
          (vendorStoreInfo) => vendorStoreInfo.identifier === params.vendorstore
        )?.facility_id;
        this.multiStoreContextSubject.next({
          facility: facilities.find(
            (facilityInfo) => facilityInfo.id === facilityId
          )!.identifier,
          vendorStore: params.vendorstore,
        });
      }
    });
  }

  private initFacility(): string {
    const params = new URL(document.location as unknown as string).searchParams;
    const facility = params.get(FACILITY_URL_KEY);

    // ideally we get the facility as a query parameter in the URL
    // if we don't get it there, then check local storage to see if
    // we remember the last set facility
    // and finally if we don't have anything there then
    // fallback to shasta
    return (
      facility ||
      localStorage.getItem(FACILITY_IDENTIFIER_KEY) ||
      environment.FACILITY_IDENTIFIER
    );
  }

  private initVendorStore(facility: string): string {
    if (!localStorage.getItem(FACILITY_VENDOR_STORES_KEY)) {
      localStorage.setItem(
        FACILITY_VENDOR_STORES_KEY,
        JSON.stringify({ [SHASTA]: FULFIL_SHASTA, [PIONEER]: FULFIL_PIONEER })
      );
    }
    const facilityVendorStores = JSON.parse(
      localStorage.getItem(FACILITY_VENDOR_STORES_KEY)!
    );
    return facilityVendorStores[facility];
  }

  private confirmVendorStore(vendorStore: string): string {
    return vendorStore;
  }

  private saveFacilityAndVendorStoreInLocalStorage() {
    const facility = this.getCurrentFacilityIdentifier();
    const facilityVendorStores = JSON.parse(
      localStorage.getItem(FACILITY_VENDOR_STORES_KEY)!
    );
    localStorage.setItem(
      FACILITY_VENDOR_STORES_KEY,
      JSON.stringify({
        ...facilityVendorStores,
        [facility]: this.getCurrentVendorStoreIdentifier(),
      })
    );
  }

  public getIdentifierforVendorstoreId(
    id: VendorStore['id']
  ): Observable<string | undefined> {
    return this.vendorStores$.pipe(
      map((vendorstores) => {
        const vs = vendorstores.find((vendorstore) => vendorstore.id === id);
        return vs?.identifier;
      })
    );
  }

  public getCurrentFacilityIdentifier(): string {
    return this.multiStoreContextSubject.getValue().facility;
  }

  public getCurrentVendorStoreIdentifier(): string {
    return this.multiStoreContextSubject.getValue().vendorStore;
  }

  public getVendorStoresFromCurrentFacility(): Observable<VendorStore[]> {
    return combineLatest([this.vendorStores$, this.vendorStoreContext$]).pipe(
      map(([vendorStores, vendorStoreIdentifier]) => {
        const facilityId = vendorStores.find(
          (vendorStore) => vendorStore.identifier === vendorStoreIdentifier
        )?.facility_id;
        return vendorStores.filter(
          (vendorStore) => vendorStore.facility_id === facilityId
        );
      })
    );
  }

  public getCurrentVendorStoreName(): Observable<string> {
    return combineLatest([this.vendorStores$, this.vendorStoreContext$]).pipe(
      map(([vendorStores, vendorStoreIdentifier]) => {
        const name =
          vendorStores.find(
            (vendorStore) => vendorStore.identifier === vendorStoreIdentifier
          )?.name || 'UNKNOWN';
        return name;
      })
    );
  }

  public getContext(): MultiStoreContext {
    return this.multiStoreContextSubject.getValue();
  }

  public latestFacilityVendorStore(facility: string): string | null {
    const facilityVendorStores = JSON.parse(
      localStorage.getItem(FACILITY_VENDOR_STORES_KEY)!
    );
    return facilityVendorStores[facility] || null;
  }

  public async facilityIdentifierForVendorStoreId(
    vendorStoreId: number
  ): Promise<string | null> {
    return lastValueFrom(
      combineLatest([this.facilities$, this.vendorStores$]).pipe(
        map(([facilities, vendorStores]) => {
          const vendorStore = vendorStores.find(
            (vendorStoreInfo) => vendorStoreInfo.id === vendorStoreId
          );
          if (!vendorStore) return null;
          const facility = facilities.find(
            (facilityInfo) => facilityInfo.id === vendorStore.facility_id
          );
          return facility?.identifier || null;
        })
      )
    );
  }

  public getCurrentFacilityTimezone(): Promise<string | null> {
    const currentFacility = this.getCurrentFacilityIdentifier();

    return lastValueFrom(
      this.facilities$.pipe(
        map((facilities) => {
          const facility = facilities.find(
            (facilityInfo) => facilityInfo.identifier === currentFacility
          );
          if (!facility) return null;

          return facility.timezone;
        })
      )
    );
  }

  public getFacilityIdentifierById(
    facilityId?: number
  ): Observable<string | undefined> {
    if (!facilityId) return of(undefined);

    return this.facilities$.pipe(
      map((facilities) => {
        const facility = facilities.find(
          (facilityInfo) => facilityInfo.id === facilityId
        );

        if (!facility) return undefined;

        return facility.identifier;
      })
    );
  }

  public getCurrentVendorStoreId(): Promise<number | null> {
    const vendorStoreName = this.getCurrentVendorStoreIdentifier();

    return lastValueFrom(
      this.vendorStores$.pipe(
        map((vendorStores) => {
          const vendorStore = vendorStores.find((vendorStoreInfo) => {
            return vendorStoreInfo.identifier === vendorStoreName;
          });
          if (!vendorStore) return null;

          return vendorStore.id;
        })
      )
    );
  }
}
