import { Injectable } from '@angular/core';
import { compact } from 'lodash';
import { BehaviorSubject, lastValueFrom, map, Observable } from 'rxjs';
import {
  pageRoutes,
  customPageRoutes,
  RouteClaims,
} from 'src/app/app-routing.module';
import { AuthService } from './auth.service';
import { CloudApiService } from './cloud-api.service';
import { AccessTokenService } from './access-token.service';

export type NavItem = {
  title: string;
  path?: string;
  img?: string;
  icon: string;
  isPinned?: boolean;
  claims?: RouteClaims;
};

interface DashboardNavPref {
  id: number;
  user_id: number;
  tile_name: string;
  tile_path: string;
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  private _pinnedNavItems = new BehaviorSubject<NavItem[]>([]);
  get pinnedNavItems(): Observable<NavItem[]> {
    return this._pinnedNavItems
      .asObservable()
      .pipe(this.navItemsWithPermissions);
  }

  private navItemsWithPermissions = map((navItems: NavItem[]) =>
    navItems.filter((route) => {
      const { claims } = route;
      if (!claims) {
        return true;
      }
      return claims.every(({ claim, claimValue }) =>
        this.accessTokenService.hasClaim(claim, claimValue)
      );
    })
  );

  private allHomePageTiles = [...pageRoutes, ...customPageRoutes]
    .filter((route) => route.data.pageMetadata.showOnHomepage)
    .map((route) => ({
      title: route.data.pageMetadata.title,
      path: `/${route.data.pageMetadata.customPath || route.path}`,
      img: route.data.pageMetadata.img,
      icon: route.data.pageMetadata.icon,
      claims: route.data.claims,
    }));

  get homePageTiles(): Observable<NavItem[]> {
    return this._pinnedNavItems.asObservable().pipe(
      // @ts-expect-error ts(2345)
      map((pinnedNavItems) => {
        return this.allHomePageTiles.map((homePageTile) => ({
          ...homePageTile,
          isPinned: !!pinnedNavItems.find(
            ({ path }) => homePageTile.path === path
          ),
        }));
      }),
      this.navItemsWithPermissions
    );
  }

  constructor(
    private cloudApi: CloudApiService,
    private authService: AuthService,
    private accessTokenService: AccessTokenService
  ) {}

  async fetchPinnedNavItems() {
    const userId = this.authService.getUserId();
    const results = await lastValueFrom(
      this.cloudApi.get<DashboardNavPref[]>(
        `dashboardnavprefs?user_id=${userId}`
      )
    );
    this._pinnedNavItems.next(
      // @ts-expect-error ts(2345)
      compact(
        results.map((navPref) =>
          this.allHomePageTiles.find(({ path }) => navPref.tile_path === path)
        )
      )
    );
  }

  /**
   * Pin or unpin a tile
   * @param nav
   */
  async pin(nav: NavItem): Promise<void> {
    const userId = this.authService.getUserId();
    const { response } = await lastValueFrom(
      this.cloudApi.post<{
        response: 'unpinned' | 'created';
      }>('dashboardnavprefs', {
        user_id: userId,
        tile_name: nav.title,
        tile_path: nav.path,
      })
    );
    switch (response) {
      case 'unpinned':
        this._pinnedNavItems.next(
          this._pinnedNavItems.getValue().filter((i) => i.path !== nav.path)
        );
        break;
      case 'created':
        this._pinnedNavItems.next([...this._pinnedNavItems.getValue(), nav]);
        break;
      default:
      // do nothing
    }
  }
}
